
export function useAppFooter() {
    const {$web} = useNuxtApp();
    const config = useRuntimeConfig();
    const {t} = useI18n();

    const footerItems = [
        {
            title: t('label.about_spoferan'),
            items: [
                {to: config.public.aboutUrl, text: t('label.general'), exact: true},
                {to: `${config.public.aboutUrl}/spoferan`, text: 'SPOFERAN'},
                {to: `${config.public.aboutUrl}/resultastic`, text: 'resultastic'},
                {to: `${config.public.aboutUrl}/clubmanager`, text: 'Clubmanager'},
                {to: `${config.public.aboutUrl}/businessmanager`, text: 'Businessmanager'},
            ]
        },
        {
            title: t('label.company'),
            items: [
                {to: `${config.public.pressUrl}/about`, text: t('label.general')},
                {to: config.public.jobsUrl, text: t('label.jobs')},
                {to: config.public.pressUrl, text: t('label.press')},
                {to: $web('team'), text: t('label.team')},
            ]
        },
        {
            title: t('label.help_and_contact'),
            items: [
                {to: $web('contact'), text: t('label.contact')},
                {to: $web('support'), text: t('label.support')},
                {to: config.public.guideUrl, text: t('label.guide')},
                {to: `${config.public.guideUrl}/spoferan/faq`, text: t('label.faq')},
            ]
        },
        {
            title: t('label.policies'),
            items: [
                {to: $web('gtc'), text: t('label.gtc')},
                {to: $web('privacy'), text: t('label.privacy')},
                {to: $web('imprint'), text: t('label.imprint')},
            ]
        },
    ];

    return {
        footerItems
    }
}